import React from 'react';
import styled from 'styled-components';
import { variables, breakpoints } from '../../styles/variables';
import { responsiveFont } from '../../styles/utils';

interface FourOhFourProps {
  tagline: string;
  text: string;
}

const TextWrap = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  margin-top: 30px;
  margin-bottom: 0;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    margin-bottom: 90px;
  }
`;

const Text = styled.span`
  ${responsiveFont(160, 440)};
  margin-left: -0.09em;
  font-weight: 600;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  color: white;
  letter-spacing: -0.09em;
  line-height: 1;
  margin-bottom: 0;
`;

const Hidden = styled.span`
  color: transparent;
  visibility: hidden;
`;

const Tagline = styled.p`
  ${responsiveFont(20, 26)};

  margin: 40px auto;
  padding: 0 50px;
  font-weight: 500;
  text-align: center;
  color: white;
`;

export const FourOhFour: React.FC<FourOhFourProps> = ({
  tagline,
  text = '4',
}) => {
  return (
    <TextWrap>
      <Text>4<Hidden>0</Hidden>4</Text>
      <Tagline>{tagline}</Tagline>
    </TextWrap>
  );
};
