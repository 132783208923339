import React, { useRef, useEffect, useMemo } from 'react';
import {
  Canvas,
  useLoader,
  useThree,
  extend,
  useFrame,
} from 'react-three-fiber';
import { GLTFLoader, GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Mesh, Box3, Vector3, ReinhardToneMapping, Vector2 } from 'three';
import { useSpring, a } from 'react-spring/three';
import { useDrag, addV } from 'react-use-gesture';
import { config } from 'react-spring';
import { FourOhFourSnowfall } from '../../components/scene/objects/Snowfall';
import debounce from 'lodash/debounce';

// tslint:disable-next-line: no-var-requires
const PotatoGLTF = require('../../../src/assets/models/christmas/potato.glb');

extend({ OrbitControls });

const Camera = () => {
  useFrame(({ ready, gl, scene, camera }) => {
    if (!ready) {
      return;
    }

    camera.layers.set(0);
    gl.render(scene, camera);

    gl.clearDepth();

    camera.layers.set(2);
    gl.render(scene, camera);
  }, 0);

  return null;
};

const PotatoModel = (props: any) => {
  const gltf = useLoader(GLTFLoader as any, PotatoGLTF) as GLTF;

  const ref = useRef<Mesh>();

  const normalScale = useMemo(() => new Vector2(1, 1), []);

  return (
    <a.mesh layers={2 as any} ref={ref} {...props} scale={[0.08, 0.08, 0.08]}>
      <a.bufferGeometry attach="geometry" {...gltf.__$[6].geometry} />
      <a.meshStandardMaterial
        attach="material"
        {...gltf.__$[6].material}
        metalness={0.0001}
        roughness={1.2}
        normalScale={normalScale}
      />
    </a.mesh>
  );
};

export const Potato = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const [props, setProps] = useSpring(() => ({
    rotation: [Math.PI / 2, 0, 0],
    position: [
      0,
      0,
      -7 + 0.002 * (typeof window !== 'undefined' ? window.innerWidth : 1920),
    ],
    config: config.slow,
  }));

  const bind = useDrag(
    ({ delta: [x], memo = props.rotation.getValue() }) => {
      setProps({ rotation: addV(memo, [0, x * 0.5, x * 0.5]) });
    },
    { domTarget: typeof window !== 'undefined' ? window : undefined }
  );

  useEffect(bind as any, []);

  const handleResize = debounce(() => {
    const distance = -7 + 0.002 * window.innerWidth;
    setProps({ position: [0, 0, distance] });
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Canvas
      onCreated={({ gl, camera }) => {
        gl.toneMapping = ReinhardToneMapping;
        gl.gammaOutput = true;
        gl.gammaFactor = 2.2;

        gl.autoClear = false;

        camera.position.set(0, -1.5, 5);
        camera.lookAt(0, -1.5, -1);
      }}
      style={{ background: 'transparent' }}
    >
      <React.Suspense fallback={null}>
        <PotatoModel {...props} />
        <ambientLight layers={2 as any} intensity={1} />

        <directionalLight layers={2 as any} intensity={1} position={[0, 1, 0]} />
        <directionalLight layers={2 as any}intensity={1} position={[1, 0, -1]} />
        <directionalLight layers={2 as any} intensity={1} position={[1, 0, 1]} />

        <FourOhFourSnowfall
          particles={2000}
          enabled={true}
          timeout={0}
          width={20}
          height={20}
          depth={5}
          position={[0, -5, -1]}
          startingY={() => 2 * (Math.random() - 0.5) * 10}
        />
      </React.Suspense>

      <Camera />
    </Canvas>
  );
};
