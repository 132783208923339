import React from 'react';
import styled, { css } from 'styled-components';

interface CenteringWrapperProps {
  z: number;
}

export const CenteringWrapper = styled.div<CenteringWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  ${(props) => css`
    z-index: ${props.z};
  `}
`;
