import React, { useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import styled, { css } from 'styled-components';

// tslint:disable-next-line: no-var-requires
const url = require('../../assets/images/landscape.png');

const LandscapeImageContainer = styled(animated.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
`;

const LandscapeImage = styled.img`
  width: 100%;
`;

export const Landscape = () => {
  const [props, set] = useSpring(() => ({ loaded: 0 }));

  const handleLoaded = () => {
    set({ loaded: 1, config: config.molasses });
  };

  useEffect(() => {
    const image = new Image();
    image.onload = handleLoaded;
    image.src = url;
  }, []);

  return (
    <LandscapeImageContainer
      style={{
        transform: props.loaded.interpolate(
          (d) => `translate3d(0, ${100 * (1 - d)}%, 0)`
        ),
      }}
    >
      <LandscapeImage src={url} />
    </LandscapeImageContainer>
  );
};
