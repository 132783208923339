import * as React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { breakpoints, variables } from '../styles/variables';

import { Link } from 'gatsby';
import LogoSvg from '../assets/svg/logo.svg';
import WordmarkSvg from '../assets/svg/wordmark.svg';

import { Potato } from '../components/potato/Potato';
import { CenteringWrapper } from '../components/centering-wrapper/CenteringWrapper';
import { FourOhFour } from '../components/four-oh-four/FourOhFour';
import { Landscape } from '../components/landscape/Landscape';

const Logo = styled(LogoSvg)`
  display: block;

  height: 14px;
  width: auto;

  color: ${variables.colors.font};
  transition: color 300ms;

  @media (min-width: ${breakpoints.md}) {
    height: 17px;
  }
`;


const LogoWordmark = styled(WordmarkSvg)`
  margin-top: 8px;
  color: ${variables.colors.font};

  height: 24px;
  width: 165px;

  transition: color 300ms;

  @media (min-width: ${breakpoints.md}) {
    height: 24px;
    width: 164px;
  }
`;

const NovaLogo = styled(Link)<NovaLogoProps>`
  position: fixed;
  display: flex;
  z-index: 4;
  flex-direction: column;

  align-items: flex-start;
  text-decoration: none;

  top: 30px;
  left: 30px;

  @media (min-width: ${breakpoints.lg}) {
    top: 50px;
    left: 50px;
  }

  ${Logo} {
    color: #fff;
  }

  ${LogoWordmark} {
    color: #fff;
  }
`;

export default () => (
  <>
    <Helmet title="404, síðan týnd!" />

    <NovaLogo to="/">
      <Logo />
      <LogoWordmark />
    </NovaLogo>

    <CenteringWrapper z={3}>
      <Potato />
    </CenteringWrapper>

    <CenteringWrapper z={2}>
      <FourOhFour
        text="4"
        tagline="Síðan er týnd og einhver fær kartöflu í skóinn."
      />
    </CenteringWrapper>

    <CenteringWrapper z={1}>
      <Landscape />
    </CenteringWrapper>
  </>
);
